setTimeout(typeWriter2, 2000)
setTimeout(typeWriter3, 5000)


var i = 0;
var i2 = 0;
var i3 = 0;

var txt = 'Hello!'; 
var text = "I'm Felix. A Copenhagen-based "; 
var tex = 'Digital Designer.'; 

var speed = 100; 
var speed2 = 100; 
var speed3 = 100; 


window.onload = function typeWriter() {
  if (i < txt.length) {
    document.getElementById("demo").innerHTML += txt.charAt(i);
    i++;
    setTimeout(typeWriter, speed);
  } 
}

function typeWriter2() {
  if (i2 < text.length) {
    document.getElementById("demo2").innerHTML += text.charAt(i2);
    i2++;
    setTimeout(typeWriter2, speed2);
  } 
}

function typeWriter3() {
  if (i3 < tex.length) {
    document.getElementById("demo3").innerHTML += tex.charAt(i3);
    i3++;
    setTimeout(typeWriter3, speed3);
  } 
}




let progressBar = document.querySelector(".progress-bar");
let documentHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
 
window.onscroll = function(){
   let progress = (scrollY / documentHeight) * 100;
   progressBar.style.height = progress + "%";
}
